import './App.css';
import React, { useEffect } from "react";
import {
  BrowserRouter,
  Switch,
  Route,
  useLocation
} from "react-router-dom";

import NavBar from "./components/NavBar"
import Header from "./components/Header"
import About from "./components/About"
import Home from "./components/Home"
import Footer from "./components/Footer"
import Socials from "./components/Socials"

import ReactGA from 'react-ga4';

ReactGA.initialize('G-QPM74C6W2T'); // Replace with your Measurement ID
ReactGA.send('pageview'); // Track the initial page view

const Analytics = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname });
  }, [location]);

  return null;
};

export default function App() {

  return (
    <div className="App">
    <Header />
    <BrowserRouter>
      <Analytics />
      <NavBar />
        <Switch>
          <Route path="/About" component={About} />
          <Route exact path="/" component={Home} />
        </Switch>
    </BrowserRouter>
    <Socials />
    <Footer />
    </div>
  );
}
